<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item navbar-brand-mini-wrapper">
          <a class="navbar-brand brand-logo-mini" href="#"><img src="@/assets/images/logo-mini.svg" alt="logo" /></a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/graph">
            <span class="menu-title">{{ $t('sidebar.graphEditor') }}</span>
            <i class="mdi mdi-24px mdi-graph menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/devices">
            <span class="menu-title">{{$t('sidebar.devices')}}</span>
            <i class="mdi mdi-24px mdi-view-list menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/transports">
            <span class="menu-title">{{ $t('sidebar.transports') }}</span>
            <i class="mdi mdi-24px mdi-truck menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/display">
            <span class="menu-title">{{ $t('sidebar.displayEmulator') }}</span>
            <i class="mdi mdi-24px mdi-monitor-dashboard menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/terminals">
            <span class="menu-title">{{ $t('sidebar.terminalMonitoring') }}</span>
            <i class="mdi mdi-24px mdi-human-dolly menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/recognitions">
            <span class="menu-title">{{ $t('sidebar.recognitions') }}</span>
            <i class="mdi mdi-24px mdi-cctv menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/events">
            <span class="menu-title">{{ $t('sidebar.events') }}</span>
            <i class="mdi mdi-24px mdi-calendar menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/config">
            <span class="menu-title">{{ $t('sidebar.config') }}</span>
            <i class="mdi mdi-24px mdi-panorama-sphere-outline menu-icon"></i>
          </router-link>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'sidebar',
 data () {
    return {
      manager: false,
      collapses: [ 
     { show: false },
     { show: false },
     { show: false }
    ]
    }
  },

  methods: {
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted () {
    const permission = localStorage.getItem("zbm_manager")
    if (permission){this.manager = permission==="true"}
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  } 
}
</script>