<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://ftf-international.com/" target="_blank">FTF International AG </a>2023</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><a href="https://ftf-international.com/solutions/#car" target="_blank">FTF SiteNav v{{this.appVersion}}</a></span>
      </div>
    </footer>
  </section>
</template>

<script>
import {version} from '../../../package.json'
export default {
  data(){
    return{
      appVersion:version
    }
  },
  name: 'app-footer'
}
</script>