<template>
  <div></div>
</template>
<script>

  export default {
    //eslint-disable-next-line
  name: "alert",
  data() {
    return {
      interval2: null,
      alerts:[],
      alertsDisplayed:[],
      maxAlertCount:5
    }
  },
    mounted() {
      this.loadAlerts()
      this.interval2 = setInterval(this.loadAlerts,7000)
    },
  beforeDestroy() {
    clearInterval(this.interval2)
    console.log("clear")
  },
  methods: {
    loadAlerts(){
      let promise = fetch("/api/alerts?acknowledged=false",{method:"GET",headers: new Headers({
          'Authorization': 'Bearer '+localStorage.getItem("token")
        })});
      promise.then((data) => {
        if (data.ok) {
          data.json().then((array) =>{
            array.forEach((value) => {
              if(!this.alertsDisplayed.some((item) => item.id === value.id)){
                this.alerts.push(value)
              }
            })
            this.displayAlerts()
          })
        } else {
          this.$bvToast.toast(this.$t('display.errordotdot')+" "+data.status+" "+data.statusText, {
            role: 'alert',
            title: this.$t('display.error'),
            variant: 'danger',
            solid: true
          })
        }
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast(this.$t('display.errordotdot') + error.toString(), {
          role: 'alert',
          title: this.$t('display.error'),
          variant: 'danger',
          solid: true
        })
      })
    },
    testAlert(){
      this.showToast("1","HIGH","Device 1","2023-11-30T13:53:00.000Z","Camera disconnected")
    },
    showToast(id,severity,device,timestamp,message) {
      let ago = this.getTimeElapsedString(timestamp)
      let variant = null
      switch (severity.toUpperCase()) {
        case "CRITICAL":
          variant = "danger"
          break;
        case "HIGH":
          variant = "danger"
          break;
        case "MEDIUM":
          variant = "warning"
          break;
        case "LOW":
          variant = "info"
          break;
        default:
          variant = "danger"
      }
      // Use a shorter name for this.$createElement
      const h = this.$createElement
      // Create the message
      const vNodesMsg = h(
          'p',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mb-0'] },
          [
            h('strong', { class: 'mr-2 ml-2' }, device+": "+message),
            h(
                'b-button',
                {
                  class: ['ml-auto'],
                  props: { variant: variant, size: 'sm' },
                  on: { click: () => this.hideAlert(id) },
                },
                this.$t('alerts.acknowledge')
            )
          ]
      )
      // Create the title
      const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            severity==="CRITICAL"?h('b-spinner', { props: { type: 'grow', small: true } }):null,
            h('strong', { class: 'mr-2 ml-2' }, severity+ this.$t('alerts.severityAlert')),
            h('small', { class: 'ml-auto text-italics' }, ago)
          ]
      )
      this.$bvToast.toast([vNodesMsg], {
        id:id.toString(),
        title: vNodesTitle,
        noCloseButton: true,
        noAutoHide: true,
        variant: variant,
        toaster: 'b-toaster-top-full',
        solid: true
      })
      this.alertsCurrentlyShown++
    },
    getTimeElapsedString(dateTime) {
      const now = new Date();
      const eventTime = new Date(dateTime);

      const elapsedMilliseconds = now - eventTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const elapsedMinutes = Math.floor(elapsedSeconds / 60);
      const elapsedHours = Math.floor(elapsedMinutes / 60);
      const elapsedDays = Math.floor(elapsedHours / 24);

      if (elapsedDays > 0) {
        return elapsedDays === 1 ? this.$t('alerts.agoDE')+'1'+this.$t('alerts.day')+this.$t('alerts.ago') :this.$t('alerts.agoDE')+ elapsedDays+this.$t('alerts.days')+this.$t('alerts.ago');
      } else if (elapsedHours > 0) {
        const remainingMinutes = elapsedMinutes % 60;
        return elapsedHours === 1
            ? this.$t('alerts.agoDE')+'1'+this.$t('alerts.hour') + (remainingMinutes === 0 ? '' : (remainingMinutes+this.$t('alerts.minutes')))+this.$t('alerts.ago')
            : this.$t('alerts.agoDE')+elapsedHours +this.$t('alerts.hours')+ (remainingMinutes === 0 ? '' : remainingMinutes+this.$t('alerts.minutes'))+this.$t('alerts.ago');
      } else if (elapsedMinutes > 0) {
        return elapsedMinutes === 1 ? this.$t('alerts.agoDE')+'1'+this.$t('alerts.minute')+this.$t('alerts.ago') : this.$t('alerts.agoDE')+elapsedMinutes+this.$t('alerts.minutes')+this.$t('alerts.ago');
      } else {
        return this.$t('alerts.justNow');
      }
    },
    hideAlert(id) {
      let promise = fetch("/api/alerts/"+id,{method:"POST",headers: new Headers({
          'Authorization': 'Bearer '+localStorage.getItem("token")
        })});
      promise.then((data) => {
        if (data.ok) {
          this.$bvToast.hide(id.toString())
          this.displayAlerts()
        } else {
          this.$bvToast.toast(this.$t('display.errordotdot')+" "+data.status+" "+data.statusText, {
            role: 'alert',
            title: this.$t('display.error'),
            variant: 'danger',
            solid: true
          })
        }
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        this.$bvToast.toast(this.$t('display.errordotdot') + error.toString(), {
          role: 'alert',
          title: this.$t('display.error'),
          variant: 'danger',
          solid: true
        })
      })
    },
    displayAlerts(){
      while (this.maxAlertCount>this.$bvToast['_vm'].$children.length){
        let value = this.alerts.pop()
        if (!value){
          return
        }
        this.alertsDisplayed.push(value)
        this.showToast(value.id,value.severity,value.displayName,value.timestamp,value.message)
      }
    }
  },
}
</script>