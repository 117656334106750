import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CountryFlag from 'vue-country-flag'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import VueMermaid from "vue-mermaid/src";
import Keycloak from "keycloak-js"
Vue.use(VueMermaid);
Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false

let initOptions = {
  url: 'https://auth.tttsolutions.hu/', realm: 'FTF', clientId: 'sitenav-ui', onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.log("Authenticated");
    new Vue({
      router,
      el: '#app',
      i18n,
      render: h => h(App, { props: { keycloak: keycloak } })
    }).$mount('#app')
    console.log(keycloak.tokenParsed)
    const username = keycloak.tokenParsed.given_name + ' ' + keycloak.tokenParsed.family_name
    const monogram = keycloak.tokenParsed.given_name[0]+keycloak.tokenParsed.family_name[0]
    const token = keycloak.token
    let user = false
    let manager = false
    if(keycloak.tokenParsed.resource_access["zbm-ui"]){
      user = keycloak.tokenParsed.resource_access["zbm-ui"].roles.includes("zbm_ui_user")
      manager = keycloak.tokenParsed.resource_access["zbm-ui"].roles.includes("zbm_ui_manager")
    }
    localStorage.setItem("username",username)
    localStorage.setItem("monogram",monogram)
    localStorage.setItem("token",token)
    localStorage.setItem("zbm_user",user.toString())
    localStorage.setItem("zbm_manager",manager.toString())
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        localStorage.setItem("token",keycloak.token)
      }
    }).catch(() => {
      console.log('Failed to refresh token');
    });
  }, 6000)

}).catch((reason) => {
  console.log(reason)
  console.log("Authenticated Failed");
});
