<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" toggleable="false">
    <b-modal
        id="prevent-access"
        ref="modal"
        :title="$t('header.alert')"
        @hidden="handleClose"
        @ok="handleClose"
        footer-bg-variant="light"
        header-bg-variant="light"
        body-bg-variant="light"
        cancel-disabled
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        ok-only
        :ok-title="$t('header.signOut')"
    >
      <h3>{{$t('header.insufficientPermission')}}</h3>
      <h6>{{$t('header.permissionHelp')}}</h6>
    </b-modal>
    <alert></alert>
    <div class="text-center navbar-brand-wrapper d-flex align-items-center">
      <a class="navbar-brand brand-logo">
        <router-link class="nav-link" to="/">
        <img style="max-width: 80%" src="@/assets/images/logo.svg" alt="logo" class="logo-dark" />
        <img src="@/assets/images/logo-light.svg" alt="logo-light" class="logo-light">
        </router-link>
      </a>
      <a class="navbar-brand brand-logo-mini" > <router-link class="nav-link" to="/events"><img src="@/assets/images/logo-mini.svg" alt="logo" /></router-link></a>
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"  @click="toggleSidebar()">
        <span class="icon-menu"></span>
      </button>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <h5 class="mb-0 font-weight-medium d-none d-lg-flex">{{$t('header.headerTitle')}}</h5>
      <ul class="navbar-nav navbar-nav-right">
        <b-nav-item-dropdown right class="preview-list navbar-dropdown ml-2 d-none d-md-flex">
          <template slot="button-content" >
            <div v-if="this.$i18n.locale==='en'" class="nav-link count-indicator message-dropdown p-0 m-0">
              <div class="d-inline-flex mr-3">
                <country-flag country='us' rounded style="align-self: baseline"/>
              </div>
              <span class="profile-text font-weight-normal"> {{$t('header.english')}}</span>
            </div>
            <div v-if="this.$i18n.locale==='de'" class="nav-link count-indicator message-dropdown p-0 m-0">
              <div class="d-inline-flex mr-3">
                <country-flag country='de' rounded style="align-self: baseline"/>
              </div>
              <span class="profile-text font-weight-normal"> {{$t('header.german')}}</span>
            </div>
          </template>
          <b-dropdown-item @click="changeLocale('en')">
            <a class="preview-item d-flex align-items-center">
              <country-flag country='us' rounded style="align-self: baseline"/> <div class="m-2">{{$t('header.english')}}</div>
            </a>
          </b-dropdown-item>
          <b-dropdown-item @click="changeLocale('de')">
            <a class="preview-item d-flex align-items-center">
              <country-flag country='de' rounded style="align-self: baseline"/> <div class="m-2">{{$t('header.german')}}</div>
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="preview-list user-dropdown navbar-dropdown ml-3">
          <template slot="button-content" >
            <div class="nav-link count-indicator message-dropdown p-0 m-0 ml-1">
              <b-avatar variant="primary" :text="monogram"></b-avatar> <span class="font-weight-normal m-2">{{userName}}</span>
            </div>
          </template>
          <b-dropdown-item @click="$router.push('/profile')">
            <a class="preview-item d-flex align-items-center">
              <i class="dropdown-item-icon icon-user text-primary"></i> {{$t('header.myProfile')}}
            </a>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/logout')">
            <a class="preview-item d-flex align-items-center">
              <i class="dropdown-item-icon icon-power text-primary"></i>{{$t('header.signOut')}}
            </a>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" @click="toggleMobileSidebar()">
        <span class="icon-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>

import Alert from "@/layout/partials/Alert.vue";

let headerName = "SiteNav";

export default {
  components: {Alert},
  data() {
    return {
      headerName : headerName,
      userName:this.$t('header.unknownUser'),
      monogram:"UU",
      userAccess:false
    }
  },
  name: 'app-header',
  mounted() {
    const uname = localStorage.getItem("username")
    const mgram = localStorage.getItem("monogram")
    const user = localStorage.getItem("zbm_user")
    if (uname){this.userName=uname}
    if (mgram){this.monogram=mgram}
    if (user){this.userAccess = user==="true"}
    if (!this.userAccess){
      this.$bvModal.show('prevent-access')
    }
    if(localStorage.getItem("appLang")!=null)
    {
      this.$i18n.locale=localStorage.getItem("appLang");
    }
  },
  methods: {
    changeLocale(lang){
      this.$i18n.locale = lang
      localStorage.setItem("appLang",lang)
    },
    handleClose(bvModalEvent) {
      this.$router.push('/logout')
      bvModalEvent.preventDefault()
    },
    toggleSidebar: () => {
      document.querySelector('body').classList.toggle('sidebar-icon-only');
    },
    toggleMobileSidebar: () => {
      document.querySelector('#sidebar').classList.toggle('active');
    }
  }
}
</script>

<style scoped>
</style>