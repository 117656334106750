import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'


Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages/ftf')
        }
      ]
    },
    {
      path: '/graph',
      component: layout,
      children: [
        {
          path: '',
          name: 'graph',
          component: () => import('@/pages/graph')
        }
      ]
    },
    {
      path: '/devices',
      component: layout,
      children: [
        {
          path: '',
          name: 'devices',
          component: () => import('@/pages/devices')
        }
      ]
    },
    {
      path: '/transports',
      component: layout,
      children: [
        {
          path: '',
          name: 'transports',
          component: () => import('@/pages/transports')
        }
      ]
    },
    {
      path: '/transport',
      component: layout,
      children: [
        {
          path: '',
          name: 'transport',
          component: () => import('@/pages/transports')
        }
      ]
    },
    {
      path: '/display',
      component: layout,
      children: [
        {
          path: '',
          name: 'display',
          component: () => import('@/pages/display')
        }
      ]
    },
    {
      path: '/terminals',
      component: layout,
      children: [
        {
          path: '',
          name: 'terminals',
          component: () => import('@/pages/terminals')
        }
      ]
    },
    {
      path: '/recognitions',
      component: layout,
      children: [
        {
          path: '',
          name: 'recognitions',
          component: () => import('@/pages/recognitions')
        }
      ]
    },
    {
      path: '/events',
      component: layout,
      children: [
        {
          path: '',
          name: 'events',
          component: () => import('@/pages/events')
        }
      ]
    },
    {
      path: '/config',
      component: layout,
      children: [
        {
          path: '',
          name: 'config',
          component: () => import('@/pages/config')
        }
      ]
    },
    {
      path: '/users',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://auth.tttsolutions.hu/admin/FTF/console/#/FTF/groups/e9fc1f34-6158-46e6-9c4f-6929b8d7778e")
      }
    },
    {
      path: '/logout',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://auth.tttsolutions.hu/realms/FTF/protocol/openid-connect/logout")
      }
    },
    {
      path: '/profile',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://auth.tttsolutions.hu/realms/FTF/account/")
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
